import React from 'react';
import './HomeContentItem.scss';
import { Link } from 'react-router-dom';
import { ContentDetail } from '../../../hooks/contents/useContentList';

type HomeContentItemProps = {
  content: ContentDetail;
};

const HomeContentItem = ({ content }: HomeContentItemProps) => {
  const returnClassNameOfCategory = () => {
    switch (content.categorys.category1) {
      case '낙상방지':
        return 'fall-prevention';
      case '정신건강':
        return 'mental-health';
      case '재활':
        return 'rehabilition';
      default:
        break;
    }
  };

  return (
    <Link to={`/contents/details/${content.videoCode}`} className='HomeContentItem'>
      <div className='home-content-top'>
        <div className={`home-content-category ${returnClassNameOfCategory()}`}>{content.categorys.category1}</div>
        <img className='home-content-thumbnail' src={content.imgList[0].imgUrl} alt='content-thumbnail' />
      </div>
      <div className='home-content-bottom'>
        <div className='home-content-name'>{content.contentName}</div>
      </div>
    </Link>
  );
};

export default HomeContentItem;
