import useSWR, { mutate } from 'swr';

const KEY = 'current_report';

export default function useReport() {
  const { data: currentReport = 'Fall Prevention', mutate } = useSWR<string>(KEY);

  const showReport = (report: string) => {
    mutate(report);
  };
  return {
    currentReport,
    showReport,
  };
}

export const showReport = (report: string) => {
  mutate(report);
};
