import useSWR, { mutate } from 'swr';
import ContentFilter from '../../types/Filter';

const KEY = 'content_filter';

export default function useContentFilter() {
  const { data: contentFilter = ContentFilter.ALL, mutate } = useSWR<ContentFilter>(KEY);

  const setFilter = async (category: ContentFilter) => {
    await mutate(category);
  };

  return {
    contentFilter,
    setFilter,
  };
}

export const setFilter = (category: ContentFilter) => {
  mutate(KEY, category, true);
};
