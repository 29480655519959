import React from 'react';
import './ReportBtnList.scss';
import { ReportBtnItem } from '../ReportBtnItem';

export type ReportItem = {
  en: string;
  ko: string;
  activeClass: string;
};

const ReportBtnList = () => {
  const reportList: ReportItem[] = [
    { en: 'Fall Prevention', ko: '낙상방지', activeClass: 'fall-active' },
    { en: 'Mental Health', ko: '정신건강', activeClass: 'mental-active' },
    { en: 'Rehabilitation', ko: '재활', activeClass: 'rehabilitaion-active' },
  ];

  return (
    <div className='ReportBtnList'>
      {reportList.map((report) => (
        <ReportBtnItem key={report.en} report={report} />
      ))}
    </div>
  );
};

export default ReportBtnList;
