import React from 'react';
import './ContentDetailTitle.scss';
import { ContentDetail } from '../../../hooks/contents/useContentList';
type ContentDetailTitleProps = {
  contentDetails?: ContentDetail;
};
const ContentDetailTitle = ({ contentDetails }: ContentDetailTitleProps) => {
  const returnClassNameOfCategory = () => {
    switch (contentDetails?.categorys.category1) {
      case '낙상방지':
        return 'fall-prevention';
      case '정신건강':
        return 'mental-health';
      case '재활':
        return 'rehabilition';
      default:
        break;
    }
  };

  return (
    <div className='ContentDetailTitle'>
      <span className={`content-detail-category ${returnClassNameOfCategory()}`}>
        {contentDetails?.categorys.category1}
      </span>
      <span className='content-detail-name'>
        {contentDetails?.categorys.category2 && `${contentDetails?.categorys.category2} - `}
        {contentDetails?.contentName}
      </span>
    </div>
  );
};

export default ContentDetailTitle;
