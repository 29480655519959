import React from 'react';
import Pagination from 'rc-pagination';
import { HomeContentItem } from '../HomeContentItem';
import useCurrentPage from '../../../hooks/contents/useCurrentPage';
import './HomeContentList.scss';
import useContentFilter from '../../../hooks/contents/useContentFilter';
import useContentList from '../../../hooks/contents/useContentList';

const HomeContentList = () => {
  const { contentList } = useContentList();
  const { contentFilter } = useContentFilter();
  const { currentPage, setPage } = useCurrentPage();

  const limit: number = 8;
  const filteredList =
    contentFilter === 'all'
      ? contentList
      : contentList.filter((content) => content.categorys.category1 === contentFilter);

  const onClickPage = (page: number) => {
    setPage(page);
    window.scrollTo(0, 0);
  };

  return (
    <div className='HomeContentList'>
      <div className='home-content-list'>
        {filteredList.slice(Math.ceil((currentPage - 1) * limit), currentPage * limit).map((content) => (
          <HomeContentItem key={content.videoCode} content={content} />
        ))}
      </div>
      <div className='home-content-pagination'>
        <Pagination
          className='pagination'
          total={Math.ceil(filteredList.length / limit) * limit}
          current={currentPage}
          onChange={onClickPage}
          pageSize={limit}
          prevIcon={'<'}
          nextIcon={'>'}
          jumpPrevIcon={''}
          jumpNextIcon={''}
        />
      </div>
    </div>
  );
};

export default HomeContentList;
