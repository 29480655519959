import React from 'react';
import './ReportSampleImg.scss';
import useReport from '../../../hooks/useReport';

type SampleImg = {
  name: string;
  imgUrl: string;
};
const ReportSampleImg = () => {
  const { currentReport } = useReport();
  const sampleImgList: SampleImg[] = [
    { name: 'Fall Prevention', imgUrl: '/images/reports/F_P_P.jpg' },
    { name: 'Mental Health', imgUrl: '/images/reports/M_H_P.jpg' },
    { name: 'Rehabilitation', imgUrl: '/images/reports/R_P.jpg' },
  ];
  const reportInfo: SampleImg | undefined = sampleImgList.find((sample) => sample.name === currentReport);
  return (
    <div className='ReportSampleImg'>
      <img src={reportInfo?.imgUrl} alt='report_img' />
    </div>
  );
};

export default ReportSampleImg;
