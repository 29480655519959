import React from 'react';
import './GradingReportBox.scss';
import { Link } from 'react-router-dom';
const GradingReportBox = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className='GradingReportBox'>
      <Link to='/grading-report'>
        <div className='grading-report-btn'>Grading Report</div>
      </Link>
      <div className='grading-report-box-content'>{children}</div>
    </div>
  );
};

export default GradingReportBox;
