import React from 'react';
import './ContentDetailVideo.scss';
import { ContentDetail } from '../../../hooks/contents/useContentList';
type ContentDetailVideoProps = {
  contentDetails?: ContentDetail;
};
const ContentDetailVideo = ({ contentDetails }: ContentDetailVideoProps) => {
  return (
    <div className='ContentDetailVideo'>
      <div className='content-detail-video'>
        <iframe title='youtube' src={`https://youtube.com/embed/${contentDetails?.videoCode}`} allowFullScreen={true} />
      </div>
    </div>
  );
};

export default ContentDetailVideo;
