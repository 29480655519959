import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { HomePage, GradingReportPage } from '../pages';
import { NotFoundPage } from '../pages/errors';
import { ContentDetailPage } from '../pages/contents';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path='/' component={HomePage} />
        <Route path='/contents/details/:contentId' component={ContentDetailPage} />
        <Route path='/grading-report' component={GradingReportPage} />
        <Route component={NotFoundPage} />
      </Switch>
    </Router>
  );
}

export default App;
