import useSWR from 'swr';

const KEY = 'content_list';

type ContentImg = {
  imgIdx: number;
  imgUrl: string;
  isThumbnail: boolean;
};

type Categorys = {
  category1: string;
  category2?: string;
};

export type ContentDetail = {
  contentName: string;
  categorys: Categorys;
  explain: string;
  videoCode: string;
  imgList: ContentImg[];
};

const initialContentList: ContentDetail[] = [
  {
    contentName: '낙상방지',
    categorys: { category1: '낙상방지' },
    explain: `- 낙상 방지를 위한 체험형 교육 콘텐츠
- 일상 환경에서 접할 수 있는 낙상 사고 예방
- 문제를 풀고 체험하는 학습형 콘텐츠`,
    videoCode: 'ftoSuQwmjC0',
    imgList: [
      {
        imgIdx: 0,
        imgUrl:
          'https://api-storage.cloud.toast.com/v1/AUTH_eecaade34a5942ebbcd67c8630ddddff/images/%EB%82%99%EC%83%81%EB%B0%A9%EC%A7%80/%EB%82%99%EC%83%81%EB%B0%A9%EC%A7%80.PNG',
        isThumbnail: true,
      },
    ],
  },
  {
    contentName: '숲 명상',
    categorys: { category1: '정신건강', category2: '우울증' },
    explain: `- 심신 안정, 우울증 예방 등 콘텐츠`,
    videoCode: 'r0nGFvThY0Y',
    imgList: [
      {
        imgIdx: 0,
        imgUrl:
          'https://api-storage.cloud.toast.com/v1/AUTH_eecaade34a5942ebbcd67c8630ddddff/images/%EC%A0%95%EC%8B%A0%EA%B1%B4%EA%B0%95/%EC%9A%B0%EC%9A%B8%EC%A6%9D/%EC%88%B2.PNG',
        isThumbnail: true,
      },
    ],
  },
  {
    contentName: '들판 명상',
    categorys: { category1: '정신건강', category2: '우울증' },
    explain: `- 심신 안정, 우울증 예방 등 콘텐츠`,
    videoCode: 'vQWu23LJ9uk',
    imgList: [
      {
        imgIdx: 0,
        imgUrl:
          'https://api-storage.cloud.toast.com/v1/AUTH_eecaade34a5942ebbcd67c8630ddddff/images/%EC%A0%95%EC%8B%A0%EA%B1%B4%EA%B0%95/%EC%9A%B0%EC%9A%B8%EC%A6%9D/%EB%93%A4%ED%8C%90.PNG',
        isThumbnail: true,
      },
    ],
  },
  {
    contentName: '한국 여행',
    categorys: { category1: '정신건강', category2: '우울증' },
    explain: `- 심신 안정, 우울증 예방 등 콘텐츠
한국여행,해외 여행 콘텐츠 체험 가능`,
    videoCode: 'k5NMggzeE14',
    imgList: [
      {
        imgIdx: 0,
        imgUrl:
          'https://api-storage.cloud.toast.com/v1/AUTH_eecaade34a5942ebbcd67c8630ddddff/images/%EC%A0%95%EC%8B%A0%EA%B1%B4%EA%B0%95/%EC%9A%B0%EC%9A%B8%EC%A6%9D/%ED%95%9C%EA%B5%AD%EB%9E%9C%EB%93%9C%EB%A7%88%ED%81%AC.PNG',
        isThumbnail: true,
      },
    ],
  },
  {
    contentName: '마켓에서 장보기',
    categorys: { category1: '정신건강', category2: '치매' },
    explain: `마켓에서의 구입 활동 및 기억력 활동을 통한 치매 예방 콘텐츠`,
    videoCode: 'CoLYQwJWOk8',
    imgList: [
      {
        imgIdx: 0,
        imgUrl:
          'https://api-storage.cloud.toast.com/v1/AUTH_eecaade34a5942ebbcd67c8630ddddff/images/%EC%A0%95%EC%8B%A0%EA%B1%B4%EA%B0%95/%EC%B9%98%EB%A7%A4/%EB%A7%88%EC%BC%93.PNG',
        isThumbnail: true,
      },
    ],
  },
  {
    contentName: '옷가게에서 옷고르기',
    categorys: { category1: '정신건강', category2: '치매' },
    explain: `옷가게에서의 구입 활동 및 기억력 활동을 통한 치매 예방 콘텐츠`,
    videoCode: '1HZvJrCONaE',
    imgList: [
      {
        imgIdx: 0,
        imgUrl:
          'https://api-storage.cloud.toast.com/v1/AUTH_eecaade34a5942ebbcd67c8630ddddff/images/%EC%A0%95%EC%8B%A0%EA%B1%B4%EA%B0%95/%EC%B9%98%EB%A7%A4/%EC%98%B7%EA%B0%80%EA%B2%8C.PNG',
        isThumbnail: true,
      },
    ],
  },
  {
    contentName: '신발가게에서 신발 고르기',
    categorys: { category1: '정신건강', category2: '치매' },
    explain: `신발가게에서의 구입 활동 및 기억력 활동을 통한 치매 예방 콘텐츠`,
    videoCode: '2PGR7qRJgS8',
    imgList: [
      {
        imgIdx: 0,
        imgUrl:
          'https://api-storage.cloud.toast.com/v1/AUTH_eecaade34a5942ebbcd67c8630ddddff/images/%EC%A0%95%EC%8B%A0%EA%B1%B4%EA%B0%95/%EC%B9%98%EB%A7%A4/%EC%8B%A0%EB%B0%9C%EA%B0%80%EA%B2%8C.PNG',
        isThumbnail: true,
      },
    ],
  },
  {
    contentName: '부엌에서 차만들기',
    categorys: { category1: '정신건강', category2: '치매' },
    explain: `부엌에서의 기억력 활동을 통한 치매 예방 콘텐츠`,
    videoCode: 'qLBMCAeRX5w',
    imgList: [
      {
        imgIdx: 0,
        imgUrl:
          'https://api-storage.cloud.toast.com/v1/AUTH_eecaade34a5942ebbcd67c8630ddddff/images/%EC%A0%95%EC%8B%A0%EA%B1%B4%EA%B0%95/%EC%B9%98%EB%A7%A4/%EC%B0%A8%20%EB%A7%8C%EB%93%A4%EA%B8%B0.PNG',
        isThumbnail: true,
      },
    ],
  },
  {
    contentName: '부엌에서 재료꺼내기',
    categorys: { category1: '정신건강', category2: '치매' },
    explain: `부엌에서의 기억력 활동을 통한 치매 예방 콘텐츠`,
    videoCode: 'xHYjoOmW2tE',
    imgList: [
      {
        imgIdx: 0,
        imgUrl:
          'https://api-storage.cloud.toast.com/v1/AUTH_eecaade34a5942ebbcd67c8630ddddff/images/%EC%A0%95%EC%8B%A0%EA%B1%B4%EA%B0%95/%EC%B9%98%EB%A7%A4/%EC%9E%AC%EB%A3%8C%EA%BA%BC%EB%82%B4%EA%B8%B0.png',
        isThumbnail: true,
      },
    ],
  },
  {
    contentName: '부엌에서 스프만들기',
    categorys: { category1: '정신건강', category2: '치매' },
    explain: `부엌에서의 기억력 활동을 통한 치매 예방 콘텐츠`,
    videoCode: 'EXYLP0jgLO0',
    imgList: [
      {
        imgIdx: 0,
        imgUrl:
          'https://api-storage.cloud.toast.com/v1/AUTH_eecaade34a5942ebbcd67c8630ddddff/images/%EC%A0%95%EC%8B%A0%EA%B1%B4%EA%B0%95/%EC%B9%98%EB%A7%A4/%EC%8A%A4%ED%94%84%20%EB%A7%8C%EB%93%A4%EA%B8%B0.png',
        isThumbnail: true,
      },
    ],
  },
  {
    contentName: '화장실에서 약꺼내기',
    categorys: { category1: '정신건강', category2: '치매' },
    explain: `화장실에서의 기억력 활동을 통한 치매 예방 콘텐츠`,
    videoCode: 'f3AcqcZEPqA',
    imgList: [
      {
        imgIdx: 0,
        imgUrl:
          'https://api-storage.cloud.toast.com/v1/AUTH_eecaade34a5942ebbcd67c8630ddddff/images/%EC%A0%95%EC%8B%A0%EA%B1%B4%EA%B0%95/%EC%B9%98%EB%A7%A4/%EC%95%BD%EA%BA%BC%EB%82%B4%EA%B8%B0.png',
        isThumbnail: true,
      },
    ],
  },
  {
    contentName: '화장실 정리하기',
    categorys: { category1: '정신건강', category2: '치매' },
    explain: `화장실에서의 기억력 활동을 통한 치매 예방 콘텐츠`,
    videoCode: 'nCoq9U-gTHs',
    imgList: [
      {
        imgIdx: 0,
        imgUrl:
          'https://api-storage.cloud.toast.com/v1/AUTH_eecaade34a5942ebbcd67c8630ddddff/images/%EC%A0%95%EC%8B%A0%EA%B1%B4%EA%B0%95/%EC%B9%98%EB%A7%A4/%EC%A0%95%EB%A6%AC%ED%95%98%EA%B8%B0.png',
        isThumbnail: true,
      },
    ],
  },
  {
    contentName: '거실에서 키찾기',
    categorys: { category1: '정신건강', category2: '치매' },
    explain: `거실에서의 기억력 활동을 통한 치매 예방 콘텐츠
키 찾기,리모컨 찾기, 핸드폰 찾기 콘텐츠 체험 가능`,
    videoCode: 'KsFl9GY8aB0',
    imgList: [
      {
        imgIdx: 0,
        imgUrl:
          'https://api-storage.cloud.toast.com/v1/AUTH_eecaade34a5942ebbcd67c8630ddddff/images/%EC%A0%95%EC%8B%A0%EA%B1%B4%EA%B0%95/%EC%B9%98%EB%A7%A4/%ED%82%A4%EC%B0%BE%EA%B8%B0.png',
        isThumbnail: true,
      },
    ],
  },
  {
    contentName: '부엌',
    categorys: { category1: '재활' },
    explain: `재미 있는 컨텐츠를 통한 쉬운 재활
- 가상현실을 통한 썰기 활동으로 팔 재활운동이 가능한 콘텐츠`,
    videoCode: 'XU1OxVl6xfw',
    imgList: [
      {
        imgIdx: 0,
        imgUrl:
          'https://api-storage.cloud.toast.com/v1/AUTH_eecaade34a5942ebbcd67c8630ddddff/images/%EC%9E%AC%ED%99%9C/%EB%B6%80%EC%97%8C.png',
        isThumbnail: true,
      },
    ],
  },
  {
    contentName: '공장',
    categorys: { category1: '재활' },
    explain: `재미 있는 컨텐츠를 통한 쉬운 재활
- 가상현실을 통한 문열기 활동으로 재활운동이 가능한 콘텐츠`,
    videoCode: 'ySYxgjJ_YqY',
    imgList: [
      {
        imgIdx: 0,
        imgUrl:
          'https://api-storage.cloud.toast.com/v1/AUTH_eecaade34a5942ebbcd67c8630ddddff/images/%EC%9E%AC%ED%99%9C/%EA%B3%B5%EC%9E%A5.png',
        isThumbnail: true,
      },
    ],
  },
];

export default function useContentList() {
  const { data: contentList = initialContentList } = useSWR<ContentDetail[]>(KEY);

  return {
    contentList,
  };
}
