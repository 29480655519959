import React from 'react';
import { PageTemplate } from '../components/base/PageTemplate';
import { Header } from '../components/base/Header';
import { Footer } from '../components/base/Footer';
import { GradingReportBox } from '../components/common/GradingReportBox';
import { HomeContentList } from '../components/home/HomeContentList';
import { HomeContentCategorys } from '../components/home/HomeContentCategorys';
import { CloudBackground } from '../components/base/CloudBackground';
import { MrwareBanner } from '../components/common/MrwareBanner';

const HomePage = () => {
  return (
    <>
      <CloudBackground />
      <PageTemplate header={<Header />} footer={<Footer />}>
        <GradingReportBox>
          <MrwareBanner />
          <HomeContentCategorys />
          <HomeContentList />
        </GradingReportBox>
      </PageTemplate>
    </>
  );
};

export default HomePage;
