import React from 'react';
import './Header.scss';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <div className='Header'>
      <div className='header-wrap'>
        <Link to='/'>
          <img src='/images/logo.png' alt='logo' />
        </Link>
      </div>
    </div>
  );
};

export default Header;
