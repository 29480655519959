import React from 'react';
import './ReportBtnItem.scss';
import useReport from '../../../hooks/useReport';
import { ReportItem } from '../ReportBtnList/ReportBtnList';

type ReportButtonProps = {
  report: ReportItem;
};

const ReportBtnItem = ({ report }: ReportButtonProps) => {
  const { showReport, currentReport } = useReport();
  const onClickCategory = (category: string) => {
    showReport(category);
  };
  return (
    <button
      className={`ReportBtnItem ${currentReport === report.en ? report.activeClass : ''}`}
      onClick={() => onClickCategory(report.en)}
    >
      {report.ko}
    </button>
  );
};

export default ReportBtnItem;
