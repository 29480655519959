import React from 'react';
import { PageTemplate } from '../components/base/PageTemplate';
import { Header } from '../components/base/Header';
import { Footer } from '../components/base/Footer';
import { GradingReportBox } from '../components/common/GradingReportBox';
import { MrwareBanner } from '../components/common/MrwareBanner';
import { CloudBackground } from '../components/base/CloudBackground';
import { ReportBtnList } from '../components/gradingReport/ReportBtnList';
import { ReportSampleImg } from '../components/gradingReport/ReportSampleImg';

const GradingReportPage = () => {
  return (
    <>
      <CloudBackground />
      <PageTemplate header={<Header />} footer={<Footer />}>
        <GradingReportBox>
          <MrwareBanner />
          <ReportBtnList />
          <ReportSampleImg />
        </GradingReportBox>
      </PageTemplate>
    </>
  );
};

export default GradingReportPage;
