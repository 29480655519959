import React from 'react';
import { PageTemplate } from '../../components/base/PageTemplate';
import { Header } from '../../components/base/Header';
import { Footer } from '../../components/base/Footer';
import { GradingReportBox } from '../../components/common/GradingReportBox';
import { CloudBackground } from '../../components/base/CloudBackground';
import { useParams, useHistory } from 'react-router-dom';
import { ContentDetailTitle } from '../../components/contentDetail/ContentDetailTitle';
import { ContentDetailVideo } from '../../components/contentDetail/ContentDetailVideo';
import { ContentDetailExplain } from '../../components/contentDetail/ContentDetailExplain';
import useContentList from '../../hooks/contents/useContentList';

type Params = {
  contentId: string;
};

const ContentDetailPage = () => {
  const { contentList } = useContentList();
  const history = useHistory();
  const params = useParams<Params>();
  const videoCode = params.contentId;

  const contentDetails = contentList.find((content) => content.videoCode === videoCode);

  if (!contentDetails) history.goBack();
  return (
    <>
      <CloudBackground />
      <PageTemplate header={<Header />} footer={<Footer />}>
        <GradingReportBox>
          <ContentDetailTitle contentDetails={contentDetails} />
          <ContentDetailVideo contentDetails={contentDetails} />
          <ContentDetailExplain contentDetails={contentDetails} />
        </GradingReportBox>
      </PageTemplate>
    </>
  );
};

export default ContentDetailPage;
