import React from 'react';
import './ContentDetailExplain.scss';
import { ContentDetail } from '../../../hooks/contents/useContentList';

type ContentDetailExplainProps = {
  contentDetails?: ContentDetail;
};

const ContentDetailExplain = ({ contentDetails }: ContentDetailExplainProps) => {
  return (
    <div className='ContentDetailExplain'>
      <pre>{contentDetails?.explain}</pre>
    </div>
  );
};

export default ContentDetailExplain;
