import React from 'react';
import './HomeContentCategorys.scss';
import { setPage } from '../../../hooks/contents/useCurrentPage';
import { setFilter } from '../../../hooks/contents/useContentFilter';
import ContentFilter from '../../../types/Filter';

const HomeContentCategorys = () => {
  const onClickFilter = (filter: ContentFilter) => {
    setPage(1);
    setFilter(filter);
  };

  return (
    <div className='HomeContentCategorys'>
      <div className='home-content-categorys'>
        <span onClick={() => onClickFilter(ContentFilter.ALL)}>전체</span> |{' '}
        <span onClick={() => onClickFilter(ContentFilter.FALL)}>낙상방지</span> |{' '}
        <span onClick={() => onClickFilter(ContentFilter.MENTAL_HEALTH)}>정신건강</span> |{' '}
        <span onClick={() => onClickFilter(ContentFilter.REHABILITATION)}>재활</span>
      </div>
    </div>
  );
};

export default HomeContentCategorys;
