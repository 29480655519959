import useSWR, { mutate } from 'swr';

const KEY = 'current_page';

export default function useCurrentPage() {
  const { data: currentPage = 1, mutate } = useSWR<number>(KEY);

  const setPage = (page: number) => mutate(page);

  return { currentPage, setPage };
}

export const setPage = (page: number) => mutate(KEY, page);
