import React from 'react';
import './Footer.scss';
const Footer = () => {
  return (
    <div className='Footer'>
      <div className='footer-wrap'>
        <div className='footer-left'>
          <p>
            ㈜글로브포인트 | 경기도 고양시 일산동구 태극로 60, 1603호 (장항동, 빛마루) | 대표 조상용 | 고객센터
            031-911-(0601/0609), gpsales@globepoint.co.kr
          </p>
          <p>Copyright ⓒ Globepoint Inc. All rights reserved.</p>
        </div>
        <div className='footer-right'>
          <select name='site-language'>
            <option value=''>언어(Language)</option>
            <option value='ko'>한국어</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default Footer;
