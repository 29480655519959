import React from 'react';
import './MrwareBanner.scss';
const MrwareBanner = () => {
  return (
    <div className='MrwareBanner'>
      <div className='mrware-banner-wrap'>
        <img src='/images/banner.png' alt='mrware_banner' />
      </div>
    </div>
  );
};

export default MrwareBanner;
