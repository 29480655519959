import React from 'react';
import { useHistory } from 'react-router-dom';

const NotFoundPage = () => {
  const history = useHistory();
  history.push('/');

  return <div>NotFound 404</div>;
};

export default NotFoundPage;
